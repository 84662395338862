<template>
    <template v-if="isLoading || row.isPropertiesLoading">
        <slot v-if="!hideLoadingIndicator" name="loading">
            <div class="d-md-flex justify-content-start col-12 mb-0 align-items-center">
                <div class="spinner-border spinner-border-sm me-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <span>{{ $t('Loading properties...') }}</span>
            </div>
        </slot>
    </template>
    <template v-else-if="!row.propertiesRowsArray?.length && !allProperties">
        <slot v-if="!hideNoProperties" name="noProperties">
            <div class="d-md-flex justify-content-start col-12 mb-0 align-items-center ms-2">
                <span>{{ $t('Item has no properties') }} </span>
            </div>
        </slot>
    </template>
    <template v-else>
        <slot :properties="properties" :editor="Editor" :renderer="Renderer">

            <template v-if="editMode">

                <template v-for="group in groups">
                    <OPropertiesGroup :caption="group.caption">
                        <template v-for="property in group.properties">
                            <slot name="item" :property="property" :editor="Editor" :renderer="Renderer">
                                <OPropertiesItem
                                    :title="property.title"
                                    :caption="setRequired(property) ? (property.caption ? property.caption + ' *' : property.name + ' *'): (property.caption ?? property.name)"
                                    :fieldName="property.name">
                                    <Editor :property="property" @blur="row.save()" :class="{ 'form-control': property.dataType !== 'bool' }" />
                                </OPropertiesItem>
                            </slot>
                        </template>
                    </OPropertiesGroup>

                </template>

            </template>

            <template v-else >
                <template v-for="(property, index) in filteredProperties">
                    <h6 v-if="showGroupHeader(index)" class="mb-0 mt-2">{{property.group}}</h6>
                    <div class="d-md-flex justify-content-start col-12 mb-0">
                        <span class="me-2 " :title="property.Caption">{{property.caption}}:</span>
                        <span><component :is="Renderer" :property="property"></component></span>
                    </div>
                </template>
            </template>

        </slot>
    </template>
</template>

<script setup lang="ts">
import type { DataItemModel } from 'o365-dataobject';
import type { PropertiesDefintion } from './DataObject.PropertiesData.ts';

import { OPropertiesGroup, OPropertiesItem } from "o365-data-components";
import { getDataObjectById } from 'o365-dataobject';
import './DataObject.PropertiesData.ts';
import PropertiesEditor from './components.PropertiesEditor.vue';
import { utils } from 'o365-utils';
import { ref, computed, watch, h, onMounted, nextTick } from 'vue';

export interface IProps {
    row: DataItemModel;
    /** Load all property definitions bound to the view instead of just for the existing items */
    allProperties?: boolean;
    /** Viewname on which the properties are configured */
    viewName?: string;
    /** Master bound field (from the viewName) */
    bindingField?: string;
    /** List of additional fields to load in from properties values view */
    additionalFields?: string[];
    /** When true will not show the 'No properties' element */
    hideNoProperties?: boolean;
    /** When true will not show the loading indicator */
    hideLoadingIndicator?: boolean;
    /** When true will render properties in an editable grid */
    editMode?: boolean;
    /** When true will save on editor blur */
    autosave?: boolean;
    /** WHen defined will add required indicator for properties  */
    requiredField?: string;
    /** */
    overrideFields?: { [key: string]: string };
    /**
     * Compare function that will be called on loaded properties list. Used when you want to enforce
     * custom sort order on properties.
     */
    sortFunction?: (a: PropertiesDefintion, b: PropertiesDefintion) => number;
    /**
     * Filter function for filtering out shown properties
     */
    filterFunction?: (pProperty: PropertiesDefintion, pValue: any) => boolean;
    /**
     * Required function for filtering setting a additional required criteria
     */
    requiredFunction?: (pProperty: PropertiesDefintion, pRow: DataItemModel) => boolean;
    /**
     * Makes form uneditable
     */
    disableEditable?:( pRow: DataItemModel) => boolean; 
    /**
     * Required function for filtering setting a additional required criteria
     */
    disabledFunction?: (pProperty: PropertiesDefintion) => boolean;
    /** When true will enable deletion of properties  */
    enableDelete?: boolean;
};

const props = defineProps<IProps>();
/** Get DataObject of the provided row */
function getDataObject() {
    if (props.row && props.row.dataObjectId && props.row.appId) {
        return getDataObjectById(props.row.dataObjectId, props.row.appId);
    } else {
        return undefined;
    }
}

const properties = ref([]);
const filteredProperties = computed(() => {
    if (props.filterFunction) {
        return properties.value.filter(property => {
            return props.filterFunction(property, props.row.properties[property.name]);
        });
    } else {
        return properties.value;
    }
});

const groups = computed(() => {
    const uniqueGroups = [...new Set(filteredProperties.value.map(e => e.group))];
    return uniqueGroups.map((groupName, groupIndex) => {
        const group = {};

        if (groupName) {
            group.caption = groupName;
        } else if (groupIndex === 0) {
            group.caption = $t('Properties');
        } else {
            group.caption = $t('Other');
        }

        group.properties = filteredProperties.value.filter(e => e.group === groupName);

        return group;
    });
});

const isLoading = ref(false);

async function initPropertiesForItem() {
    try {
        properties.value.length = 0;
        const dataObject = getDataObject();
        // properties.value.splice(0, properties.value.length);
        if (dataObject == null) {
            return;
        }

        const propertiesData = dataObject.propertiesData;

        isLoading.value = true;
        propertiesData.disableTracking = true;
        propertiesData.initialize({
            viewName: props.viewName,
            bindingField: props.bindingField
        });
        dataObject.hasPropertiesData = true;
        await propertiesData.initializationPromise;
        let additionalFields = new Set<string>();
        if (props.additionalFields) {
            props.additionalFields.forEach(field => additionalFields.add(field));
        }

        if (props.requiredField) {
            additionalFields.add(props.requiredField);
        }
        if (additionalFields.size > 0) {
            //console.log(propertiesData)
            propertiesData.setAdditionalFields(props.additionalFields);
        }

        // get properties
        propertiesData.enable();
        const existingProperties = await propertiesData.getExistingPropertiesForItem(props.row, props.allProperties);
        propertiesData.setProperties(existingProperties);
        const propertiesRows: Array<DataItemModel> = await propertiesData.getPropertiesForItem(props.row.ID);

        // set properties value
        properties.value = propertiesData.selectedProperties.map(name => {
            const definition = propertiesData.propertiesDefinitions[name];
            const property = { ...definition };

            Object.defineProperty(property, "row", {
                get() {
                    return props.row.propertiesRows[name];
                }
            });

            if (props.overrideFields) {
                const row = propertiesRows.find(e => e.PropertyName === name);
                if (row) {
                    for (let [propField, rowField] of Object.entries(props.overrideFields)) {
                        const value = row[rowField];
                        if (value) {
                            property[propField] = value;
                        }
                    }
                }
            }

            return property;
        });

        if (props.sortFunction) {
            if (props.row.isPropertiesLoading && props.row.propertiesLoadingPromise) {
                await props.row.propertiesLoadingPromise;
            }
            properties.value.sort(props.sortFunction);
        } else {
            properties.value.sort((a, b) => a.group - b.group);
        }
    } catch (ex) {
        console.error(ex);
    } finally {
        isLoading.value = false;
    }
}

function showGroupHeader(pIndex: number) {
    const current = filteredProperties.value[pIndex]?.group;
    const previous = filteredProperties.value[pIndex - 1]?.group;
    return current != previous;
}

function Renderer(props2) {
    if (props2.property.isUrl) {
        return h('a', {
            href: props.row.properties[props2.property.name]
        }, props.row.properties[props2.property.name]);
    }
 
    switch(props2.property.dataType) {
        case 'bool':
        return h('input', {
            type: 'checkbox',
            checked: props.row.properties[props2.property.name],
            disabled: true
        });
        case 'date':
            return utils.formatDate(props.row.properties[props2.property.name], 'Short Date')
        case 'datetime':
            return utils.formatDate(props.row.properties[props2.property.name], 'General Date Short Time')
        case 'decimal':
            return utils.formatNumber(props.row.properties[props2.property.name],props2.property.format);
        default:
            return props.row.properties[props2.property.name];
    }
}

function setRequired(property){
    var reqFunc = props.requiredFunction ? props.requiredFunction(property, props.row) : false;
    return props.requiredField && props.row.propertiesRows[property.name][props.requiredField] || reqFunc;
}

Renderer.props = ['property'];

function Editor(props2, ctx) {
    const disabled = props.disabledFunction ? props.disabledFunction(props2.property) : false;
    return h(PropertiesEditor, {
        'row': props.row.propertiesRows[props2.property.name],
        'modelValue': props.row.properties[props2.property.name],
        'onUpdate:modelValue': newValue => props.row.properties[props2.property.name] = newValue,
        'config': props2.property,
        'disabled': disabled
    }, ctx.slots.default
        ? {
            default: ({wrapper}) => ctx.slots.default({wrapper})
        }
        : undefined);
}
Editor.props = ['property'];

watch(() => props.row, () => {
    initPropertiesForItem();
});
onMounted(() => {
    initPropertiesForItem();
});

</script>

<style>
    .required-prop {
        background-color: cornsilk;
    }

    .required-prop > textarea {
        background-color: inherit;
    }
</style>